import(/* webpackMode: "eager" */ "/vercel/path1/apps/web/src/app/(frontend)/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["SandPackCSS"] */ "/vercel/path1/libs/domains/code-editor/feature/src/sandpack-css/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/libs/domains/site-layout/feature-layout/src/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/libs/shared/ui/src/styles/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/locomotive-scroll/dist/locomotive-scroll.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/local/target.css?{\"path\":\"../../libs/shared/ui/src/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-dank-mono\",\"src\":[{\"path\":\"./dank-mono/DankMono-Regular.woff2\"},{\"path\":\"./dank-mono/DankMono-Bold.woff2\",\"weight\":\"bold\"},{\"path\":\"./dank-mono/DankMono-Italic.woff2\",\"style\":\"italic\"}]}],\"variableName\":\"dankMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/local/target.css?{\"path\":\"../../libs/shared/ui/src/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-eksell-large\",\"src\":\"./eksell/eksell-display-large-webfont.woff2\"}],\"variableName\":\"eksellLarge\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/local/target.css?{\"path\":\"../../libs/shared/ui/src/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-eksell-small\",\"src\":\"./eksell/eksell-display-small-webfont.woff2\"}],\"variableName\":\"eksellSmall\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/local/target.css?{\"path\":\"../../libs/shared/ui/src/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-silka\",\"src\":[{\"path\":\"./silka/silka-extralight-webfont.woff2\",\"weight\":\"200\"},{\"path\":\"./silka/silka-light-webfont.woff2\",\"weight\":\"300\"},{\"path\":\"./silka/silka-regular-webfont.woff2\"},{\"path\":\"./silka/silka-medium-webfont.woff2\",\"weight\":\"500\"},{\"path\":\"./silka/silka-semibold-webfont.woff2\",\"weight\":\"600\"},{\"path\":\"./silka/silka-bold-webfont.woff2\",\"weight\":\"bold\"},{\"path\":\"./silka/silka-black-webfont.woff2\",\"weight\":\"900\"},{\"path\":\"./silka/silka-regular-italic-webfont.woff2\",\"style\":\"italic\"}]}],\"variableName\":\"silka\"}");
