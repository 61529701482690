import type { VectorProps } from '@alecia/types/svg'

const AleciaLayingIllustration = (props: VectorProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 995.05 448.09"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path className="clb-4" d="M807.93,190.77c-2.13-10.23,42.25-30.63,75,25.65" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path className="clb-3" d="M799.3,190.77c-4.25-9.26,4.5-23,43-18.1" />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            className="alecia-hair-outline"
            d="M886.05,228.33c-11.5,13.77-71.25-7.41-68.75-27.41"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <path className="clb-5" d="M816.55,210.79c0,18.06,58.83,35.17,69.75,15.36" />
        </clipPath>
        <clipPath id="clip-path-5">
          <path className="clb-6" d="M815.93,218.42c-2.75,13.75,34.56,50.75,70.75,29.5" />
        </clipPath>
        <clipPath id="clip-path-6">
          <path className="clb-7" d="M811.68,227.79c-1.75,24.35,50.2,47.54,74.12,19.13" />
        </clipPath>
        <clipPath id="clip-path-7">
          <path className="clb-8" d="M804.76,254.67c-9.48,17.5,51.29,37.75,70.29,14" />
        </clipPath>
        <clipPath id="clip-path-8">
          <path className="clb-9" d="M884.43,262.67c6.29,31.5-43.89,57.68-66.88,28.75" />
        </clipPath>
        <clipPath id="clip-path-9">
          <path className="clb-10" d="M813.8,197.17c8.75-13.64,55.38-4.88,67.75,25.5" />
        </clipPath>
        <clipPath id="clip-path-10">
          <path className="clb-11" d="M772.43,188.42c-1.88-2.38-9.66-16.77,16.62-21.86" />
        </clipPath>
        <clipPath id="clip-path-11">
          <path
            className="clb-1"
            d="M841.05,175.67c-35.9-19.69-79-4-89.35,14.25a30.42,30.42,0,0,0-2.74,6.59c34.12-20.36,63.62-13.86,68.34-.36,12.56,36-19.94,67.77-6.57,81.86h0a19.36,19.36,0,0,1,2.86-.23A19.83,19.83,0,0,1,833.43,298c0,11.18-8.66,20.33-19.84,20.33-7.88,0-15-5.92-18.35-12.5l-16.72,16.89c8.93,14.64,26.46,24.13,66.28,1.57C894.3,296.21,898.1,207,841.05,175.67Z"
          />
        </clipPath>
        <radialGradient
          id="laying-gradient"
          cx="209.45"
          cy="224.09"
          r="467.81"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ffebb7" />
          <stop offset="0.22" stopColor="#fff0c9" stopOpacity="0.75" />
          <stop offset="0.58" stopColor="#fff8e6" stopOpacity="0.35" />
          <stop offset="0.84" stopColor="#fffdf8" stopOpacity="0.1" />
          <stop offset="0.95" stopColor="#fff" stopOpacity="0" />
        </radialGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="illustration_night" data-name="illustration night">
          <path
            className="alecia-socks-light"
            d="M678.33,250.68a5.77,5.77,0,0,0,.77,1.91c7.91,12.7,98.74,158.46,109.95,179.15,7.33,13.54,233.67-38.41,194.79-107.49-22-39-127.35-172.47-132.66-176.08C801.32,114.3,661.19,166.14,678.33,250.68Z"
          />
          <path
            className="alecia-socks-light"
            d="M231.38,345.67s8,74.66-23,75.66-81,0-81,0S150.38,287.33,231.38,345.67Z"
          />
          <path
            className="alecia-socks-light"
            d="M150.8,379.67s8,67.61-30.5,68.23c-50,.81-75.24-1.27-99.25-1-20.75.25-40.67-31.24,16-40.5,52-8.5,55-10.75,60-34.25S149.05,350.35,150.8,379.67Z"
          />
          <line className="alecia-socks-outline" x1="151.12" y1="387.29" x2="150.21" y2="402.84" />
          <path
            className="alecia-pants-fill"
            d="M171.55,381.17c-3,17.85-92.5,4.08-90-18.92s53.5-231.08,127-250.58c57-15.12,110.5,92.07,110.5,92.07s-5,104.41,46,107.17,63.41-.93,63.17,7.52,11.33,97.24,11.33,97.24-27.5,27-69.91,27c-47.65,0-104.59-3.91-122.09-105l-31-117S176.39,352.78,171.55,381.17Z"
          />
          <path
            className="alecia-shirt-fill"
            d="M456.55,242.54s-58.41,117.92-28.66,187.7a6.28,6.28,0,0,0,5.56,3.8c25.5.91,151,5.92,209.6,5.91,28,0,107.63-1.78,96.81-86.78L701.55,310s-20-43.68-97.5-59S456.55,242.54,456.55,242.54Z"
          />
          <path className="alecia-shirt-outline" d="M623.3,264.54s28.25,8.88,42.25,23.13" />
          <path
            className="alecia-socks-dark"
            d="M191.72,421.68c5.87-.07,11.53-.18,16.66-.35,17.23-.55,22.41-23.85,23.62-44.45C211.86,376.54,196.11,407.38,191.72,421.68Z"
          />
          <path
            className="alecia-socks-dark"
            d="M23.4,409.47C-16,421.16,2.19,447.14,21.05,446.92c3.53-.05,7.09,0,10.75,0C36.17,427,29.69,415.55,23.4,409.47Z"
          />
          <path
            className="alecia-socks-dark"
            d="M107.3,448.05q6.21,0,13-.15c23.54-.38,29.7-25.75,30.93-45.45C129.26,402.45,110.2,436.19,107.3,448.05Z"
          />
          <path
            className="alecia-pants-fill"
            d="M166.05,332.17s31.25,30.08,91.5,19l94-177.48S285.05,58,217.05,113.84,166.05,332.17,166.05,332.17Z"
          />
          <line
            className="alecia-pants-outline-lighter"
            x1="209.8"
            y1="194.17"
            x2="216.4"
            y2="223.37"
          />
          <polyline
            className="alecia-pants-outline-lighter"
            points="182.22 341.63 216.11 222.11 249.24 350.32"
          />
          <path
            className="alecia-socks-outline"
            d="M981.89,323c-7.84-12.87-29.8-16.95-67.17-5.84-74.54,22.16-130,96.13-124.92,113.68"
          />
          <path className="alecia-pants-outline-lighter" d="M223.39,110.35s32-4.27,76.37,61.22" />
          <polygon
            className="alecia-laptop-shade"
            points="285.73 329.48 294.89 175.76 306.49 177.87 296.97 317.91 451.73 327.81 451.73 339.96 285.73 329.48"
          />
          <polygon
            className="alecia-laptop-edge"
            points="517.99 241.65 517.99 254.3 451.73 339.96 451.73 327.81 517.99 241.65"
          />
          <polygon
            className="alecia-laptop-edge"
            points="296.97 317.91 306.49 177.87 388.85 113.31 381.73 232.71 296.97 317.91"
          />
          <polygon
            className="alecia-laptop-screen"
            points="317.19 184.02 309.42 290.67 374.59 227.05 380.49 130.65 317.19 184.02"
          />
          <polygon
            className="alecia-laptop-edge"
            points="296.97 317.91 381.73 232.71 517.99 241.65 451.73 327.81 296.97 317.91"
          />
          <polygon
            className="alecia-laptop-keyboard"
            points="329.81 305.64 441.56 311.89 488.19 251.89 386.56 244.65 329.81 305.64"
          />
          <polygon
            className="alecia-laptop-highlight"
            points="294.89 175.76 306.49 177.87 388.85 113.31 375.56 111.14 294.89 175.76"
          />
          <line className="alecia-laptop-outline" x1="298.17" y1="316.95" x2="381.19" y2="233.46" />
          <path
            className="alecia-skin-fill"
            d="M395.89,315.94c17.49,31.39,47.7,100,84.49,103.06,27.78,2.34,34.67,6.67,115.34,12.67,189,14.06-.45-70-.45-70s-90.35,4.36-107.22-7.32c-15.89-11-41.34-42.88-55.21-58.28C425.83,288.28,378.56,284.83,395.89,315.94Z"
          />
          <path className="alecia-skin-fingers" d="M413.43,260s20.37,14.13,13.92,33.54" />
          <path
            className="alecia-skin-fingers"
            d="M375,250.67c17.12,1.91,34.41,13.35,37.08,26.37"
          />
          <path
            className="alecia-skin-fingers"
            d="M363.63,256.68c14.38-.4,38.25,11.06,39.09,28.49"
          />
          <path
            className="alecia-skin-fingers"
            d="M358,264.67c10.66-1.25,36.75,13.69,36.75,24.33"
          />
          <path className="alecia-skin-fingers" d="M356.05,273.33s25.21,7.17,25.21,19.67" />
          <path
            className="alecia-shirt-fill"
            d="M667.05,354.33h-63.4a5.07,5.07,0,0,0-3.12,1c-17,13-16.46,71.67-4.49,84,0,0,70.43,4.43,99.81-6.63S667.05,354.33,667.05,354.33Z"
          />
          <path
            className="alecia-shirt-outline"
            d="M667.05,354.33h-63.4a5.07,5.07,0,0,0-3.12,1c-17,13-16.62,70.32-4.65,82.65"
          />
          <path
            className="alecia-skin-fill"
            d="M398.92,321.49c-5.54-11.82-21-10.32-24.2-30.17-1.8-11.28,18.62-27.41,36.5-23,14.46,3.58,10.88,15,23.24,29.76C465.07,334.55,403.19,330.62,398.92,321.49Z"
          />
          <path className="alecia-skin-outline" d="M408.36,259.36a55.61,55.61,0,0,1,10.6,14" />
          <path className="alecia-skin-outline" d="M369.64,251a52.15,52.15,0,0,1,30.62,15.29" />
          <path className="alecia-skin-outline" d="M358.16,258.41c12.42-.11,26.1,7.84,33.58,15" />
          <path className="alecia-skin-outline" d="M353.84,268.19c4.77-1.67,22.12,5,29.34,14" />
          <path
            className="alecia-hair-light-fill"
            d="M841.05,175.67c-35.9-19.69-79-4-89.35,14.25a27,27,0,0,0-2.28,6.32c34.07-19.73,62.88-13.8,67.6-.29,12.56,36-19,68.38-6.29,82.06h0a16.44,16.44,0,0,1,2.6-.54,20.25,20.25,0,0,1,20.28,20.23,20,20,0,0,1-20.1,20.05,20.22,20.22,0,0,1-18-11.09l-17.4,15.59c8.94,14.64,26.91,24.55,66.73,2C894.3,296.21,898.1,207,841.05,175.67Z"
          />
          <g className="clb-32">
            <path
              className="alecia-hair-outline"
              d="M886.05,228.33c-11.5,13.77-71.25-7.41-68.75-27.41"
            />
            <path
              className="alecia-hair-outline"
              d="M816.55,210.79c0,18.06,58.83,35.17,69.75,15.36"
            />
            <path
              className="alecia-hair-outline"
              d="M815.93,218.42c-2.75,13.75,34.56,50.75,70.75,29.5"
            />
            <path
              className="alecia-hair-outline"
              d="M811.68,227.79c-1.75,24.35,50.2,47.54,74.12,19.13"
            />
            <path
              className="alecia-hair-outline"
              d="M804.76,254.67c-9.48,17.5,51.29,37.75,70.29,14"
            />
            <path
              className="alecia-hair-outline"
              d="M884.43,262.67c6.29,31.5-43.89,57.68-66.88,28.75"
            />
            <path
              className="alecia-hair-outline"
              d="M813.8,197.17c8.75-13.64,55.38-4.88,67.75,25.5"
            />
            <path
              className="alecia-hair-outline"
              d="M772.43,188.42c-1.88-2.38-9.66-16.77,16.62-21.86"
            />
            <path
              className="alecia-hair-outline"
              d="M807.93,190.77c-2.13-10.23,42.25-30.63,75,25.65"
            />
            <path className="alecia-hair-outline" d="M799.3,190.77c-4.25-9.26,4.5-23,43-18.1" />
            <path className="alecia-hair-outline" d="M786.3,186s0-21.75,42-18.87" />
            <path
              className="alecia-hair-outline"
              d="M781.1,190.77c-5.05-7.24,0-26.48,25.95-26.48"
            />
          </g>
          <path
            className="alecia-hair-light-fill"
            d="M887.55,215.66s24.8-32.21,64.25-7.74c37.5,23.25.75,66,43.25,77,0,0-9.5,11.73-25.75,2,0,0,.25,9.73,25.75,16.75,0,0-10.46,8.66-22.39,2.86l-42.8-59.19c-14.14-13.68-28.71-20.14-39.06-4.8Z"
          />
          <path className="alecia-hair-outline" d="M934.22,210.79s32.66-1.37,31.16,53.75" />
          <path
            className="alecia-hair-outline"
            d="M890.72,227s20.38-24.14,49.33.79c22.5,19.38,10.5,43.36,28.75,58.44"
          />
          <path className="alecia-hair-outline" d="M892.22,233.29c31.5-24.29,50.77,9.14,55.27,26" />
          <path
            className="alecia-accessory"
            d="M882.82,254.58c6.25-6.2,16-23.18,8.48-37.2-5.54-10.36-14.33-13.33-21-13.4C879.39,219.14,883.42,237,882.82,254.58Z"
          />
          <path
            className="alecia-skin-fill"
            d="M813.51,277.26a19.36,19.36,0,0,0-2.86.23h0c-11.93-13.34,19.13-45.88,6.57-81.86-5.52-15.81-44.19-21-86.16,13.27-47.92,39.14-23.09,77.25-12.21,82l-20,16.5c-27.88,20.12,33.37,55.12,42,48.25l54.62-49a20.24,20.24,0,1,0,18-29.41Z"
          />
          <path
            className="alecia-skin-outline"
            d="M739.86,230.28s-1.37-5.5-6.2-3.8c-4.58,1.61-2.32,7.55-2.32,7.55"
          />
          <path className="alecia-skin-outline" d="M736.35,251c1.72,2.62,1.95,4.37,1,6.82" />
          <path className="alecia-skin-outline" d="M737.71,254s-11.42,4-14.88-8.08" />
          <path
            className="alecia-skin-outline"
            d="M824.68,301.79c2.5-6.12-7.18-18.44-19.92-8.28,6.92-3.71,13,4,10.21,10.16"
          />
          <path
            className="alecia-eyes-light"
            d="M780.86,253c-4.77,6.93-33.44-.73-28.53-21.74C775.12,223.25,782.59,247.19,780.86,253Z"
          />
          <ellipse
            className="alecia-hair-light-fill"
            cx="788.48"
            cy="229.55"
            rx="5.98"
            ry="12.01"
            transform="translate(-30.05 163.17) rotate(-11.6)"
          />
          <path d="M752,233.26a11.2,11.2,0,1,1,11.33,19.28l-.21.09C756.34,249.21,750.65,242.71,752,233.26Z" />
          <circle className="alecia-accessory" cx="806.45" cy="317.11" r="7.14" />
          <ellipse
            className="alecia-hair-light-fill"
            cx="765.12"
            cy="194.69"
            rx="5.31"
            ry="10.67"
            transform="translate(48.25 525.45) rotate(-39.04)"
          />
          <path
            className="alecia-skin-shade"
            d="M718.84,290.89l-8.1,6.68c5.89,7.35,25.87,11.82,43.84,5.85C740.38,302.27,727.93,298,718.84,290.89Z"
          />
          <path
            className="alecia-eyes-light"
            d="M742.12,220.91c5.83-2,14.66-17.26,7.33-24.77A134.08,134.08,0,0,0,733.39,207C733.2,215,737.17,221.4,742.12,220.91Z"
          />
          <path d="M735.6,216.73a4.61,4.61,0,0,0,2.13.56c5.11,0,9.26-4.7,9.26-10.51a12.27,12.27,0,0,0-2.68-7.52c-3.6,2.31-7.24,4.88-10.92,7.77A18.92,18.92,0,0,0,735.6,216.73Z" />
          <path
            className="hammond-ears-outer"
            d="M660.8,115.17s16.09-12.75,35.25-5.5l-19.16,29.25Z"
          />
          <path
            className="clb-36"
            d="M650.05,177.49c.09.2.42,1,.85,2.33h0C650.42,178.3,650.05,177.5,650.05,177.49Z"
          />
          <path
            className="hammond-base-fill"
            d="M509.17,305.65a8.07,8.07,0,0,1-2.91-.55c-22.85-9-36-20.53-40.16-35.37-6.62-23.59,11.66-47.64,27.78-68.86,9.11-12,18.54-24.39,18.67-31.36.11-5.75-3-7.93-5.89-8-3.17-.14-7,2.16-7.46,9.07a8,8,0,0,1-16-1.06c1.09-16.39,13-24.45,24.12-24,10.76.46,21.5,9,21.2,24.35-.24,12.19-10.29,25.42-21.93,40.73C492.75,228.8,477,249.48,481.51,265.4c2.68,9.55,13,17.9,30.58,24.8a8,8,0,0,1-2.92,15.45Z"
          />
          <path
            className="hammond-base-fill"
            d="M502.92,286.83s-9-68.41,56.8-98.41c11.74-5.36,38.63-19.82,41.16-33.42,3.62-19.41,4.55-45.67,37.5-50.7,45.67-7,60.17,66,11.67,73.19,0,0,14.83,29.56-14.83,72.26l-2.17,41.44s20-1.56,19.8,18.65a2.6,2.6,0,0,1-2.58,2.58h-8.89v1.26a2.31,2.31,0,0,1-2.31,2.32H611.32a5.93,5.93,0,0,1-5.83-4.86l-6.2-33.54a56.47,56.47,0,0,1-23.23,17.71s18.59,2.62,18.8,18a2.65,2.65,0,0,1-2.68,2.68l-74.13-.8s-12.37-.33-12.87-14.63Z"
          />
          <path
            className="hammond-highlight-fill"
            d="M680.18,141.91h0c-1.38-6.49-8.63,17.16-29.7,22.79-17.15,4.59-34.48-3.31-39.69-6a1.52,1.52,0,0,0-2.16.93c-9.24,31.59,51.06,45.84,14.08,88.32,0,.85.59,14.56.59,14.56a127.48,127.48,0,0,0,11.92-12.75c29.66-42.7,14.83-72.26,14.83-72.26,19.7-2.91,29-16.67,30.05-31.53v-.08l.06-1.26c0-.34,0-.67,0-1v-.35C680.19,142.82,680.19,142.36,680.18,141.91Z"
          />
          <path
            className="hammond-ears-outer"
            d="M635,106.77c3-3.81,16.64-19.74,31.9-18l-.61,23c-9.21-.32-16.71,6.7-16.71,6.7-4,1-13.45-4.69-15-9.62A2.27,2.27,0,0,1,635,106.77Z"
          />
          <circle cx="665.97" cy="143.67" r="3.75" />
          <path className="cat-face-outline" d="M664.72,159.58c-1.42,6,7.79,7.8,10.85-.51" />
          <path d="M672.8,155.17c-.83,1.52,2.71,5.41,4.09,5.29,1.82-.16,3.51-4.45,1.91-5.26C677.37,154.48,673.91,153.12,672.8,155.17Z" />
          <path
            className="hammond-highlight-fill"
            d="M666.32,111.8l.61-23L649.61,118.5S657.38,112,666.32,111.8Z"
          />
          <path
            className="hammond-highlight-shade"
            d="M651.74,182.7a37.68,37.68,0,0,0-1.69-5.21,41.09,41.09,0,0,1-7.5.82,37,37,0,0,1-14.83-3.09c-1-.43-1.58-.73-1.58-.73S638.55,189.23,651.74,182.7Z"
          />
          <path
            className="hammond-highlight-fill"
            d="M633.05,291.19l.91-17.35s-6.4,4.41-10.23-6.79c-4.75.67-8.72,16.31-24,13.21l5.94,32.16A4.39,4.39,0,0,0,610,316h29.1a2.26,2.26,0,0,0,2.25-2.26v-1.32h9a2.48,2.48,0,0,0,2.47-2.48C653.12,289.62,633.05,291.19,633.05,291.19Z"
          />
          <path className="hammond-highlight-outline" d="M625.05,304.92s6.75-.59,6.75,10.08" />
          <path className="hammond-highlight-outline" d="M642.3,300.5s6.47,0,6.05,10.87" />
          <path className="hammond-base-outline" d="M542.56,249.11c7.66-2.5,38.11,5,18.62,45.63" />
          <line className="hammond-base-outline" x1="591.38" y1="234.83" x2="600.22" y2="277.11" />
          <path
            className="hammond-highlight-fill"
            d="M594.86,313.35a2.61,2.61,0,0,1-2.63,2.63l-62.65-.68c11.18-.33,20.59-5.74,27.28-13a44.83,44.83,0,0,0,5.19-6.84s13.55-.21,14-.11C576.06,295.31,594.7,297.94,594.86,313.35Z"
          />
          <path className="hammond-highlight-outline" d="M579.55,303.79s8.93-.25,6.93,11" />
          <path
            className="hammond-stripe-fill"
            d="M546.69,230c-4.75-4.41-5.27-23.27-4.07-31.85a94.62,94.62,0,0,0-9.32,7.43C532.55,213.21,535.66,226.89,546.69,230Z"
          />
          <path
            className="hammond-stripe-fill"
            d="M563.13,186.8c-1.23.6-2.38,1.15-3.41,1.62-2.91,1.32-5.66,2.73-8.28,4.19-1.6,13.45,16.45,24.26,20.07,42.72C578.17,213.89,562.84,201.61,563.13,186.8Z"
          />
          <path
            className="hammond-stripe-fill"
            d="M584.58,174.2c-3,2.13-6.09,4.14-9.16,6-.57,7,10.51,15.31,13.26,25.32C593.79,194.78,584.24,182.54,584.58,174.2Z"
          />
          <path
            className="hammond-stripe-fill"
            d="M490.69,178a8,8,0,0,0,8.51-7.45,13.33,13.33,0,0,1,1.26-5.12c-2-5.31-14.16-6.56-16-3a32.42,32.42,0,0,0-1.26,7.08A8,8,0,0,0,490.69,178Z"
          />
          <path
            className="hammond-stripe-fill"
            d="M492,279.42h0c-5.29,5.27-13.33,2.24-13.33,2.24,10.15,9.64,18.41,5,21.21,2.88A61.62,61.62,0,0,1,492,279.42Z"
          />
          <path
            className="hammond-stripe-fill"
            d="M490.71,232.84c1.55-2.5,3.23-5,5-7.49-3.81,2.76-11.86,3.37-16.55,2.66A13.63,13.63,0,0,0,490.71,232.84Z"
          />
          <path
            className="hammond-stripe-fill"
            d="M504.21,210c-2.62-2-7.08-6.31-7.85-12.37l-2.48,3.27c-.78,1-1.57,2.06-2.35,3.1C495,210.57,504.21,210,504.21,210Z"
          />
          <path
            className="hammond-stripe-fill"
            d="M478.83,261.1c-7.75-2.23-11.74-5-13.61-8a41.3,41.3,0,0,0-.48,7.84C471.15,264.62,478.83,261.1,478.83,261.1Z"
          />
          <path
            className="hammond-stripe-fill"
            d="M562.61,256.44s1.61,12.49-17.42,22.26c0,0,23.44,1.32,22.59-13.14Z"
          />
          <path
            className="hammond-stripe-fill"
            d="M563.1,292.87l-1.05,2.55a44.83,44.83,0,0,1-5.19,6.84c-13.45-.13-19.85-8.66-19.85-8.66C547.34,297.93,563.1,292.87,563.1,292.87Z"
          />
          <path
            className="hammond-stripe-fill"
            d="M623,255.05s-1.3,6.21-14.35,6.46c0,0,5.35,5.73,14.69,1.4Z"
          />
          <path
            className="hammond-stripe-fill"
            d="M592.71,241s1.84,7.62,20.28,6.06c0,0-6.81,8.75-18.43,2.81Z"
          />
          <path
            className="hammond-highlight-outline"
            d="M623.94,263.21l.44,32.79s17.09.62,16.64,15.37"
          />
          <path
            id="light-laying"
            className="lying-light svg-light"
            d="M388.85,113.31,696.05,18S875.9,224.36,648.37,340l-351.4-22,9.52-140Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default AleciaLayingIllustration
